.progress-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.progress-dots div {
    width: 12px;
    height: 12px;
    margin: 0 5px; 
    border-radius: 50%;
    background-color: lightblue; 
    transition: background-color 0.3s ease;
}

.progress-dots div.active {
    background-color: #4a4aff;
}
