.login {
    height: 100vh;
}


/* TODO: change px to % of page size later */

.login-page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px; 
    padding: 40px;
    margin-top: 120px;
}

.description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 15%;
    text-align: center;
    color: #4D4DDE;
}

.description p {
    font-size: 19px;
}

.description a:visited{
    color: inherit;
}

.login-window {
    padding: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.login-form h2, .login-form p{
    margin-bottom: 0;
}

.login-form input, .login-form button {
    width: 70%; 
    height: 35px;
    padding: 8px; 
    box-sizing: border-box;
    border-radius: 6.06px;
    border: 1px solid #676666;
    border-style: solid;
}

.login-form button {
    background-color: #212121;
    color: white;
    border: none;
    border-radius: 6.06px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}
