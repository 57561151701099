.welcome, .sign-up, .additional-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px; 
    padding: 40px;
    /* height: 100vh; */
    max-width: 100vw;
}

.left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 10%;
    color: #4D4DDE;
}

.signup-form, .more-info, .brand-survey {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 5%;
    align-items: center;
}

.register-form h1 {
    margin-bottom: 40px;
}

.register-form p {
    margin: 0;
    padding: 0;
    font-weight: 100;
    font-size: small;
}

.register-form input,
.register-form button {
    width: 120%;
    height: 35px;
    padding: 8px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #676666;
}

.register-form button {
    background-color: #212121;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}
