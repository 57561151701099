.navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 50px;
}

/* TODO: adjust font size, nav bar height as percentage, and color based on the design */

.company-icon {
    flex-grow: 1;  /* Allows the company icon area to grow and push navigators to the end */
    display: flex;
    align-items: center;
}

.company-icon h1 {
    margin: 0;
    color: #4D4DDE;  
}

.vertical_bar {
    height: 45px;
}

.navigators {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}

.navigators a {
    text-decoration: none;
    font-weight: bold;  
    font-size: 18px;
    color: #3D3A3A;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
}

.navigators a:visited {
    color: inherit;
}

.registration-button {
    background-color: #4D4DDE;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    padding: 0px 20px;
}
